@import "common";

.services {
	.servicesList {
		display: flex;
		justify-content: space-around;
		max-width: 800px;
		flex-wrap: wrap;
        margin: auto auto -50px;

		.serviceElement {
			width: 50%;
			margin-bottom: 50px;
		}
	}

	@include landscape {
		.servicesList .serviceElement {
			width: calc(100% / 3);
		}
	}

	@include tablet-desktop {
		.servicesList .serviceElement {
			width: calc(100% / 3);
		}
	}
}