@import "common";

.mainFooter {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: $red;
	padding: 40px;
}

.colsWrapper {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	background-color: $red;
	color: $white;
}

.bottomFooter {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50px;
	padding: 0 20px;
	background-color: $dark-grey;
	color: $light-grey;
	@include fontSize(9);

	.copyright {
		text-align: center;
		
		span {
			white-space: nowrap;
		}
	}
}

.footerCol {
	@include fontSize(14);
	padding-left: 10px;
	padding-right: 10px;
	border-left: solid 2px rgba(255, 255, 255, 0.6);

	&:nth-child(2) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

.footerColTitle {
	font-weight: 700;
	margin-bottom: 5px;
}

.footerColInfo {
	line-height: 1.5em;
	color: rgba(255, 255, 255, 0.6);

	a:hover {
		color: $white;
	}
}


.socials {
	display: flex;
	margin-left: -7px;

	.social {
		width: 42px;
		height: 42px;

		a {
			height: 100%;
			width: 100%;
			justify-content: center;
			align-items: center;
			opacity: 0.6;

			&:hover {
				opacity: 1;
			}
		}
	}

	a,
	span {
		display: inline-flex;
	}

	.icon {
		width: 29px;
		height: 29px;
		background-repeat: no-repeat;
		background-size: 100%;
	}

	.facebook .icon {
		background-image: url('../images/icons/social/facebook-logo-58.svg');
	}

	.instagram .icon {
		background-image: url('../images/icons/social/instagram-logo.svg');
	}
}

@include tablet-desktop {
	.mainFooter {
		height: 200px;
	}

	.footerColInfo {
		@include fontSize(12);
	}

	.footerCol:nth-child(2) {
		padding-top: 0;
		padding-bottom: 0;
	}

	.colsWrapper {
		flex-direction: row;
		justify-content: space-evenly;
		width: 100%;
	}
}

@include desktop {
	.socials {
		margin-left: -5px;

		.social {
			width: 24px;
			height: 24px;
			margin: 5px;

			.icon {
				width: 100%;
				height: 100%;
			}
		}
	}

	.bottomFooter {
		@include fontSize(11);
	}
}