/* autoprefixer grid: no-autoplace */

@import "common";

.galleryWrapper {
	display: grid;
	grid-template-rows: 120px 180px 140px 140px 200px;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 5px;
	grid-template-areas:
	"image1 image1 image2 image2"
	"image3 image3 image3 image3"
	"image4 image4 image5 image5"
	"image7 image7 image7 image6"
	"image8 image8 image8 image8";
}

@for $i from 1 through 8 {
	.image#{$i} {
		grid-area: image#{$i};
	}
}

.image img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}


@include tablet-desktop {
	.galleryWrapper {
		grid-template-rows: 400px 200px 200px 400px 600px;
		grid-template-columns: repeat(6, 1fr);
		grid-gap: 10px;
		grid-template-areas: 
		"image1 image1 image2 image2 image2 image2"
		"image3 image3 image3 image3 image4 image4"
		"image3 image3 image3 image3 image5 image5"
		"image6 image6 image6 image7 image7 image7"
		"image8 image8 image8 image8 image8 image8";
	}
}