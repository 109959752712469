@import "common";

.home {
	.mainDescription {
		height: $mainDescription-height;
		background-color: $light-grey;
		padding: 40px;
		margin: 0 15px;
		display: flex;
		align-items: center;

		.title {
			@include Oswald;
			@include fontSize(32);
			line-height: 0.8em;
			font-weight: 300;

			strong {
				font-weight: 400;
			}
		}
	}

	.serviceBox {
		height: 340px;
		width: 280px;
		margin: 15px auto;
		display: flex;
		flex-direction: column;

		.serviceImage {
			height: 164px;
			background-color: #ddd; 
			background-size: cover;
			background-repeat: no-repeat;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.serviceBottom {
			flex-grow: 1;
			background-color: $light-grey;
			position: relative;
			padding: 50px 15px 15px;
			text-align: center;
			color: $grey;
		}

		.serviceTitle {
			@include Oswald;
			@include fontSize(20);
			text-transform: uppercase;
			letter-spacing: .05em;
			font-weight: 500;
			margin-bottom: 5px;
		}

		.serviceText {
			@include fontSize(14);
		}

		.serviceIcon {
			height: 90px;
			width: 90px;
			background-color: $white;
			border-radius: 50%;
			position: absolute;
			top: -45px;
			left: calc(50% - 45px);
			background-size: 50%;
			background-repeat: no-repeat;
			background-position: center;
		}

		&.autoriparazioni {
			img {
				object-position: center right;
			}

			.serviceIcon {
				background-image: url('../images/icons/services/screwdriver-and-wrench-crossed.svg');
			}	
		}

		&.gommista {
			img {
				object-position: center;
			}

			.serviceIcon {
				background-image: url('../images/icons/services/car-wheel.svg');
			}
		}

		&.assistenza-stradale {
			img {
				object-position: bottom;
			}

			.serviceIcon {
				background-image: url('../images/icons/services/truck-carrying-car.svg');
			}
		}
	}

	.allServices {
		display: flex;
		justify-content: center;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 30px;
		background-color: $white;

		button {
			@include Oswald;
			@include fontSize(16);
			padding: 10px;
			text-transform: uppercase;	
			cursor: pointer;

			div {
				left: -1px;
			}
		}
	}

	@include desktop {
		.slideshow {
			position: relative;
		}

		.mainDescription {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 33%;
			margin: 0;
			background-color: rgba(238, 238, 238, 0.6);
			color: $black;
			display: flex;
			justify-content: center;
			flex-direction: column;
			height: auto;
			padding: 15px;

			.text {
				width: 300px;
			}

			.title {
				@include fontSize(38);
				line-height: .9em;
			}
		}

		.servicesSection {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			padding-top: 35px;

			.serviceBox {
				margin: 15px;
				z-index: -1;
				transform: translateY(50%);
				opacity: 0;
				transition: transform 0.7s ease-out, opacity 0.7s ease-out;
				height: auto;

				&.animate {
					transform: translateY(0);
					opacity: 1;
				}

				.serviceImage {
					height: 190px;
				}

				.serviceBottom {
					padding: 50px 20px 20px;
				}
			}

			.allServices {
				padding-bottom: 50px;
			}
		}
	}
}