a {
    text-decoration: none;
    color: inherit;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin Oswald {
    font-family: 'Oswald', sans-serif;
}

@mixin OpenSans {
    font-family: 'Open Sans', sans-serif;
}

@function calculateRem($size) {
    @return $size / 16 * 1rem;
}

@mixin fontSize($size) {
    font-size: $size * 1px;
    font-size: calculateRem($size);
}
