@import "common";
$header-sidePadding--small: 40px;
$header-sidePadding--large: 70px;

$header-sideColumns-mobile--small: 40px;
$header-sideColumns-mobile--large: 60px;

$burgerMenu-rowHeight: 3px;
$burgerMenu-width--small: 20px;
$burgerMenu-height--small: 15px;
$burgerMenu-width--large: 25px;
$burgerMenu-height--large: 17px;

header {
	position: sticky;
	top: 0;
	z-index: 1;
}

.mainHeader {
	display: flex;
	height: $mainHeader-height;
	position: relative;
	z-index: 2;
	background-color: $white;

	> * {
		height: 100%;
		align-items: center;
	}
}

.phoneBar {
	height: $phoneBar-height;
	min-width: $breakpoint-mobile-min;
	background-color: $red;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: .05em;
	font-weight: 600;

	.text {
		@include fontSize(12);
		margin-right: 5px;
	}

	.tel {
		text-decoration: underline;
	}
}

.hamburger,
.logo {
	display: flex;
	justify-content: center;
}

.hamburger {
	width: $header-sideColumns-mobile--large;

	.icon {
		display: flex;
		align-items: center;
		width: $burgerMenu-width--large;
		height: $burgerMenu-height--large;
		border-color: $dark-grey;
		border-top: solid $burgerMenu-rowHeight;
		border-bottom: solid $burgerMenu-rowHeight;

		&:before,
		&:after {
			content: '';
			display: block;
			background-color: $dark-grey;
			height: $burgerMenu-rowHeight;
			width: $burgerMenu-width--large;
			position: absolute;
		}
	}

	&.open .icon {
		border: none;

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}

.logo {
	//font-family: "Helvetica Neue";
	flex-grow: 1;
	margin-right: $header-sideColumns-mobile--large;
	flex-direction: column;

	a {
		text-align: center;

		> div {
			line-height: 1em;
		}
	}

	.logoTitle,
	.logoSubtitle {
		color: $red;
	}

	.logoTop {
		@include Oswald;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 400;
		color: $grey;
		margin-bottom: 2px;
	}

	.logoSubtitle {
		font-size: 14px;
		font-weight: 400;
	}

	.logoTitle {
		font-size: 26px;
		font-weight: 700;
		letter-spacing: -0.03em;

		span {
			font-size: 18px;
			margin-left: 5px;
		}
	}
}

.menu {
	color: $grey;
	text-align: center;
	font-weight: 600;
	position: fixed;
	top: $mainHeader-height;
	bottom: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $white;
	z-index: 1;
	display: flex;
	flex-direction: column;
	transform: translateY(calc(-100% + #{$phoneBar-height} ));
	
	&:not(.menu-hidden) {
		transition: all 620ms cubic-bezier(.86,0,.07,1);
		transition-timing-function: cubic-bezier(.86,0,.07,1);

		.phoneBar {
			transition: all 620ms cubic-bezier(.86,0,.07,1);
			transition-timing-function: cubic-bezier(.86,0,.07,1);
		}
	}

	&.menu-hidden nav {
		visibility: hidden;
	}

	&.open {
		transform: translateY(0);

		.phoneBar {
			color: $red;
			margin-bottom: 20%;
			background-color: transparent;
		}
	}

	nav {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	ul {
		width: 180px;
		margin-left: auto;
		margin-right: auto;
	}

	li a {
		display: block;
		width: 100%;
		padding: 15px;

		&.active {
			color: $red;

			span {
				border-bottom: solid 4px $red;
			}
		}
	}
}

.alfa {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $light-grey;
	height: $alfa-height--mobile;
	margin-top: $phoneBar-height;

	a {
		display: flex;
		align-items: center;

		.alfaLogo {
			background-image: url('../images/Alfa-Romeo-logo-2015-1920x1080.png');
			height: 40px;
			width: 40px;
			background-repeat: no-repeat;
			background-size: 100%;
			margin-right: 10px;
		}

		.alfaText {
			font-weight: 600;
			color: $grey;
			border-bottom: solid 4px $red;
		}
	}
}

@include mobile-small {
	.hamburger {
		width: $header-sideColumns-mobile--small;
	
		.icon {
			width: $burgerMenu-width--small;
			height: $burgerMenu-height--small;
	
			&:before,
			&:after {
				width: $burgerMenu-width--small;
			}
		}
	}

	.logo {
		margin-right: $header-sideColumns-mobile--small;

		.logoTitle {
			font-size: 24px;
		}
	}
}

@include mobile-tablet {
	.menu ul {
		@include fontSize(20);
	}

	.alfaText {
		@include fontSize(12);
	}
}

@include landscape {
	.menu {
		ul {
			margin-top: 0;

			a {
				padding: 10px;
			}
		}

		&.open .phoneBar {
			margin-bottom: 3%;
		}
	}
}

@include desktop {
	header {
		position: static;
		display: flex;
		flex-wrap: wrap;
	}

	.phoneBar {
		flex-basis: 100%;
		justify-content: flex-end;
	}

	.mainHeader,
	.phoneBar {
		padding: 0 $header-sidePadding--small;
	}

	.mainHeader {
		align-items: center;
	}

	.hamburger {
		display: none;
	}

	.logo {
		flex-grow: 0;
		margin-right: auto;

		.logoTitle {
			font-size: 28px;
		}
	}

	.phoneBar {
		background-color: $light-grey;
		color: $dark-grey;

		.text {
			@include fontSize(11);
		}
	}

	.menu {
		position: static !important;
		margin-left: auto;
		height: auto;
		transform: none;
		transition: none !important;
		padding-right: 50px;
		
		ul {
			display: flex;
			height: 100%;
			width: auto;
			padding: 0;
			margin: 0;

			li,
			a {
				display: flex;
				align-items: center;
				height: 100%;
			}

			li {
				border-bottom: none !important;
			}

			a {
				border-width: 4px 0;
				border-color: transparent;
				border-style: solid;
				padding: 0 15px;
				pointer-events: none;

				span {
					pointer-events: auto;
				}
			}

			li a.active {
				color: $red;
				border-bottom-color: $red;
				background-color: transparent;

				span {
					border-bottom: none;
				}
			}
		}
	}

	.alfa {
		padding-right: $header-sidePadding--small;
		background-color: transparent;
		height: auto;
		margin-top: 0;

		a {
			border-left: solid 1px $light-grey;
			padding-left: 20px;

			.alfaLogo {
				width: 60px;
				height: 60px;
			}

			.alfaText {
				border-width: 5px;
			}
		}
	}
}

@include desktop--large {
	.mainHeader,
	.phoneBar {
		padding: 0 $header-sidePadding--large;
	}

	.menu ul a {
		padding: 0 20px;
	}

	.alfa {
		padding-right: $header-sidePadding--large;
	}
}