@import "common";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

$slideshowSection-height--mobile: calc(100vh - #{$mainHeader-height} - #{$phoneBar-height} - #{$alfa-height--mobile});
$slideshowSection-height--desktop: calc(100vh - #{$mainHeader-height} - #{$phoneBar-height});

$slider-minHeight: 200px;
$slider-marginBottom: 15px;

.slideshow {
    min-height: $slider-minHeight + $slider-marginBottom + $mainDescription-height;
    height: $slideshowSection-height--mobile;

	.sliderWrapper {
        height: calc(100% - #{$mainDescription-height} - #{$slider-marginBottom} - 15px);
        min-height: 200px;
		margin-bottom: $slider-marginBottom;
	}

	.slick-slider,
	.slick-list,
	.slick-track,
	.slick-slide div {
		height: 100%;
	}

	.slick-slide img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.slick-dots {
		bottom: 10px;

		li {
			margin: 0;

			::before {
				color: $white;
				font-size: 14px;
				opacity: 1;
			}

			&.slick-active button::before {
				color: $red;
				opacity: 1;
			}
		}
	}

	@include landscape {
		height: auto;

		.sliderWrapper {
			height: $slideshowSection-height--mobile;
		}
	}

	@include desktop {
		height: $slideshowSection-height--desktop; //per sovrascrivere tablet landscape

		.sliderWrapper {
			height: 100%;
		}
	}
}