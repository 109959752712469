//Breakpoints
$breakpoint-mobile-min: 320px;
$breakpoint-mobile-small: 360px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-desktop--large: 1200px;

//Colors
$black: #000;
$white: #fff;
$light-grey: #eee;
$grey: #444;
$dark-grey: #313135;
$alfa-red: #8f0c25;
$red: #C42537;

//Header
$mainHeader-height: 90px;
$phoneBar-height: 30px;
$alfa-height--mobile: 50px;

//Home
$mainDescription-height: 230px;

//Layout
$content-maxWidth: 1200px;
