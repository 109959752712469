@import "common";

html,
body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	min-width: $breakpoint-mobile-min;
}

body {
	@include OpenSans;
	@include fontSize(16);
	min-width: 320px;
	cursor: pointer;
	color: $grey;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	-webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

.hidden {
	display: none;
}

.vis-hidden {
	visibility: hidden;
}

// *:focus {
// 	outline: none;
// }

#mainContainer {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

#mainContent {
	flex-grow: 1;
}

.pageTitle {
	height: 120px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}

	.pageTitleContent {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(196, 37, 55, 0.6);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.text {
		@include Oswald;
		@include fontSize(28);
		font-weight: 300;
		letter-spacing: .05em;
		color: $white;
		animation: 1s ease-out 0s 1 slideUp;
	}
}

.pageContent section {
	padding: 50px 15px;

	h2 {
		@include Oswald;
		@include fontSize(24);
		font-weight: bold;
		color: $grey;
		text-transform: uppercase;
	}
}

.wrapper {
	max-width: $content-maxWidth;
	margin: auto;
}

.cookie-layer {
	border-top: solid thin $white;
}

@keyframes slideUp {
	0% {
		transform: translateY(70%);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@include mobile-tablet {
	.hide-mobile {
		display: none !important;
	}
}

@include desktop {
	body {
		@include fontSize(14);
		cursor: default;
		position: static !important;
	}
	
	.pageTitle {
		height: 170px;

		.text {
			@include fontSize(35);
		}
	}

	.pageContent section h2 {
		@include fontSize(20);
		
	}

	.hide-desktop {
		display: none !important;
	}
}