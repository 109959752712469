@import "common";
$tableSidePadding: 15px;

.infoContacts {
	color: $grey;

	h2 {
		margin-bottom: 30px;
	}

	.box {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.boxSection {
		text-align: center;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		.icon {
			display: block;
			width: 24px;
			height: 24px;
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: center;
			margin: auto auto 5px;
		}

		&.address .icon {
			background-image: url('../images/icons/pin.svg');
		}

		&.phone .icon {
			background-image: url('../images/icons/phone.svg');
		}

		&.fax .icon {
			background-image: url('../images/icons/fax-machine.svg');
		}

		&.mail .icon {
			background-image: url('../images/icons/email.svg');
		}
	}

	.title,
	.day {
		color: $red;
		text-transform: uppercase;
		font-weight: 600;
	}

	.times {
		table {
			border-collapse: collapse;
		}

		td {
			padding: 3px 10px;
		}

		td:first-child {
			padding-left: $tableSidePadding;
		}

		td:last-child {
			padding-right: $tableSidePadding;
		}

		td:empty {
			display: none;
		}

		.active {
			background-color: rgba(196, 37, 55, 0.1);
			font-weight: 600;
		}

		.closed {
			color: $red;
			font-style: italic;
			text-align: center;
		}
	}

	.map {
		background-color: $light-grey;
		text-align: center;

		.mapWrapper {
			height: 400px;
			border: solid 3px $dark-grey;
		}
	}

	@include mobile-tablet {
		.times {
			padding-top: 0;
		}
	}

	@include desktop {
		.pageContent {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.boxSection {
			text-align: right;
			display: flex;

			.icon {
				order: 1;
				width: 17px;
				height: 17px;
				margin: initial;
				margin-left: 10px;
			}

			&.address,
			&.phone {
				.text {
					margin-top: -4px;
				}
			}

			&.fax,
			&.mail {
				align-items: center;
			}
		}

		.contacts,
		.times {
			width: 50%;
			justify-content: flex-start;
		}

		.map {
			width: 100%;
		}

		.contacts {
			align-items: flex-end;
		}

		.times {
			align-items: flex-start;

			table {
				margin-left: -$tableSidePadding;
				margin-top: -7px;
			}
		}
	}
}