@import "common";

.about {
	.pageTitle img {
		object-position: center 60%;
	}

	.story {
		.mainImage {
			height: 240px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 20%;
			}
		}

		.text {
			line-height: 1.5em;
			padding: 0 30px;
			margin-top: 30px;

			span {
				white-space: nowrap;
				color: $red;
			}

			h2 {
				margin-bottom: 10px;
			}
		}
	}

	.gallery {
		background-color: $light-grey;
	}

	@include tablet-desktop {
		.story {
			display: flex;
			max-width: 800px;

			> div {
				width: 50%;
			}

			.mainImage {
				height: 300px;
			}

			.text {
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-left: 30px;
				margin-top: 0;
				padding: 0;
			}
		}
	}
}