@import "common";

$elementSize: 100px;

.serviceElement {
    text-align: center;

    .icon {
        width: $elementSize;
        height: $elementSize;
        border: solid 3px $red;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 40%;
        border-radius: 50%;
        margin: auto;
    }

    .text {
        @include Oswald;
        @include fontSize(18);
        margin-top: 10px;
        color: $dark-grey;

        span {
            white-space: nowrap;
        }
    }

    @include tablet-desktop {
        .text {
            @include fontSize(20);
        }
    }
}